let mode;

if(process.env.REACT_APP_MODE){
  mode = process.env.REACT_APP_MODE
} else {
  mode = "dev";
}

var localURL = "http://localhost:3000/dev";

// pibit URL dev & prod

export var pibitUrl = "https://qypm1jivib.execute-api.us-east-1.amazonaws.com/dev";
var pibitUrlBeta = ""

// pibit clientid for dev and beta will be same, it will differ for prod.
export var clientId = 103;


// This key is added in db to differentiate client, since for dev environment pibit will have single client code.
export var clientIdInternal = "103-0"


if (mode === "local") {
  pibitUrl = localURL;
} else if(mode === "dev"){
  clientId = clientId;
  pibitUrl = pibitUrl;
  clientIdInternal = clientIdInternal
} else if(mode === "beta"){
  clientId = clientId;
  pibitUrl = pibitUrlBeta;
  clientIdInternal = clientIdInternal
}
