import React from "react";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
// import UploadForm from "./components/UploadForm";
import UploadFormAcord from "./components/UploadFormAcord";
import Navbar from "./components/navbar";
// import Amplify from 'aws-amplify';

function App() {
  return (
    <div>
      <Navbar />
      <div className="d-flex">
        {/* <UploadForm /> */}
        <UploadFormAcord />
      </div>
    </div>
  );
}

export default App;
